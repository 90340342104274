import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import PageIntroTextbox from '../components/PageIntroTextbox'
import Seo from '../components/Seo'
import SupportVideoGrid from '../components/SupportVideoGrid'
import { baseGrid } from '../theme/mixins'
import { colors } from '../theme/variables'

const SupportPage = () => {
  const { page } = useStaticQuery(graphql`
    query {
      page: datoCmsSupportPage {
        heading
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        supportVideos {
          title
          video {
            url
            thumbnailUrl
          }
        }
        seo {
          title
          description
        }
      }
    }
  `)
  const mainStyle = css`
    ${baseGrid}
    grid-template-rows: auto var(--gutter-mlg) auto;
    &:before {
      content: '';
      grid-column: 1 / -1;
      grid-row: 2 / span all;
      height: 100%;
      background-color: ${colors.ecruShade1};
    }
  `
  const introStyle = css`
    grid-row: 1 / 3;
    margin-bottom: 0;
  `
  const videoGridStyle = css`
    grid-column: 2 / -2;
    grid-row: 3 / 4;
    margin-top: var(--gutter-lg);
  `
  return (
    <Layout mainCss={mainStyle}>
      <Seo
        title={page.seo.title || page.heading}
        description={page.seo.description}
      />
      <PageIntroTextbox
        heading={page.heading}
        bodyNode={page.bodyNode}
        css={introStyle}
      />
      <SupportVideoGrid
        supportVideos={page.supportVideos}
        css={videoGridStyle}
      />
    </Layout>
  )
}

export default SupportPage
