import { css, keyframes } from '@emotion/react'
import React from 'react'

import { mq } from '../theme/mixins'
import VideoPlayer from './VideoPlayer'

const SupportVideoGrid = ({ supportVideos, ...props }) => {
  const animation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `
  const sectionStyle = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gutter-md);
    opacity: 0;
    animation: ${animation} 1200ms cubic-bezier(0.25, 0.75, 0.25, 1)
      forwards 300ms;
    margin-bottom: var(--gutter-xlg);
    ${mq().m} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--gutter-mlg);
    }
    ${mq().s} {
      grid-template-columns: 1fr;
    }
  `
  const videoWrapStyle = css`
    position: relative;
    display: flex;
    flex-direction: column;
    h2 {
      z-index: 1;
      flex: 1;
      line-height: 1.25;
      font-family: var(--sans-serif);
      font-weight: 400;
      font-size: var(--fs-24);
      width: fit-content;
      background-color: white;
      margin: -0.5em var(--gutter-sm) 0 calc(var(--gutter-sm) * -1);
      padding: 1.167em var(--gutter-sm) 0.667em;
      ${mq().s} {
        padding: 1.167em var(--gutter-md) 0.667em;
      }
    }
  `
  const videoStyle = css`
    z-index: 2;
  `

  return (
    <section css={sectionStyle} {...props}>
      {supportVideos.map((supportVideo, i) => (
        <div css={videoWrapStyle} key={i}>
          <VideoPlayer css={videoStyle} video={supportVideo.video} />
          <h2>{supportVideo.title}</h2>
        </div>
      ))}
    </section>
  )
}

export default SupportVideoGrid
